import { Title } from '@solidjs/meta';
import { AddDonation } from '../components/icons/AddDonation';
import { Donations } from '../components/icons/Donations';
import { A } from '~/components/A';
import { useNavigate } from '@solidjs/router';

export default function Index() {
	const navigate = useNavigate();
	const session = sessionStorage.getItem('accessToken');

	if (!session) {
		navigate('/login');
	}

	return (
		<main id="home" class="h-full w-full">
			<div class="mx-auto mt-32 grid max-w-[255px] grid-cols-2 gap-6 p-4">
				<Title>Home</Title>
				<A
					href="/donations/scan"
					class="col-span-2 flex flex-col items-center gap-1 rounded-md bg-white p-4 text-lg font-semibold text-orange-950 shadow transition-transform duration-300 hover:scale-110"
				>
					<AddDonation />
					<span>Add Donation</span>
				</A>
				<A
					href="/donations"
					class="flex flex-col items-center rounded-md bg-white p-2 text-xs font-semibold text-orange-950 shadow transition-transform duration-300 hover:scale-110"
				>
					<Donations />
					<span>My Donations</span>
				</A>
			</div>
		</main>
	);
}
