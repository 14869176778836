import type { JSX } from 'solid-js';

export function User(props: JSX.SvgSVGAttributes<SVGSVGElement>) {
	return (
		<svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.5 24.5C20.2997 24.5 25 19.7997 25 14C25 8.20126 20.2997 3.5 14.5 3.5C8.70035 3.5 4 8.20126 4 14C4 19.7997 8.70035 24.5 14.5 24.5Z"
				stroke="#F18E44"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.5029 15.7685C16.7588 15.7685 18.5862 13.9401 18.5862 11.6851C18.5862 9.43018 16.7588 7.60181 14.5029 7.60181C12.247 7.60181 10.4196 9.43018 10.4196 11.6851C10.4196 13.9401 12.247 15.7685 14.5029 15.7685Z"
				stroke="#F18E44"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M20.916 21.7351C19.8602 19.5984 16.9726 18.2351 14.5027 18.2351C12.0318 18.2351 9.12729 19.5984 8.07056 21.7351"
				stroke="#F18E44"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
