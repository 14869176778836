import type { JSX } from 'solid-js';

export const Logo = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => {
	return (
		<svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.35 158.85" {...props}>
			<defs>
				<style>{`.cls-1{fill:#f18e44;}.cls-2{fill:#1d1d1b;}`}</style>
			</defs>
			<path
				class="cls-2"
				d="m29.12,70.28l-12.4-35.09c-.26-.7-.52-1.83-.52-2.71,0-2.53,2.01-4.89,5.15-4.89,2.62,0,4.36,1.75,5.15,4.19l8.9,27.93,8.99-27.93c.79-2.44,2.71-4.19,5.41-4.19h.61c2.71,0,4.63,1.75,5.41,4.19l9.08,27.93,8.99-28.02c.7-2.27,2.36-4.1,5.15-4.1s4.98,2.27,4.98,4.89c0,.79-.26,1.83-.44,2.36l-12.57,35.44c-1.13,3.23-3.32,4.71-5.85,4.71h-.35c-2.62,0-4.8-1.48-5.76-4.54l-8.99-27.41-9.08,27.41c-.96,3.06-3.14,4.54-5.67,4.54h-.35c-2.62,0-4.8-1.48-5.85-4.71Z"
			/>
			<path
				class="cls-2"
				d="m90.4,51.42v-.17c0-13.18,10.48-24.09,24.62-24.09s24.53,10.74,24.53,23.92v.17c0,13.09-10.48,24.01-24.7,24.01s-24.44-10.74-24.44-23.83Zm38.59,0v-.17c0-8.12-5.85-14.84-14.14-14.84s-13.88,6.63-13.88,14.67v.17c0,8.03,5.85,14.75,14.05,14.75s13.97-6.63,13.97-14.58Z"
			/>
			<path
				class="cls-2"
				d="m150.72,32.91c0-2.97,2.27-5.33,5.24-5.33s5.32,2.36,5.32,5.33v4.71c2.44-5.76,6.98-10.13,11.61-10.13,3.32,0,5.24,2.18,5.24,5.24,0,2.79-1.83,4.63-4.19,5.06-7.51,1.31-12.66,7.07-12.66,17.98v13.62c0,2.88-2.36,5.24-5.32,5.24s-5.24-2.27-5.24-5.24v-36.49Z"
			/>
			<path
				class="cls-2"
				d="m187.81,13.87c0-2.88,2.27-5.24,5.24-5.24s5.33,2.36,5.33,5.24v55.53c0,2.97-2.36,5.24-5.33,5.24s-5.24-2.27-5.24-5.24V13.87Z"
			/>
			<path
				class="cls-2"
				d="m257.91,69.4c0,2.97-2.36,5.24-5.24,5.24s-5.33-2.27-5.33-5.24v-2.88c-3.4,4.8-8.21,8.64-15.71,8.64-10.82,0-21.39-8.64-21.39-23.92v-.17c0-15.28,10.39-23.92,21.39-23.92,7.6,0,12.4,3.75,15.71,8.12V13.87c0-2.97,2.36-5.24,5.24-5.24s5.32,2.27,5.32,5.24v55.53Zm-37.01-18.33v.17c0,8.9,6.11,14.75,13.27,14.75s13.36-5.94,13.36-14.75v-.17c0-8.9-6.29-14.75-13.36-14.75s-13.27,5.59-13.27,14.75Z"
			/>
			<path
				class="cls-2"
				d="m16.5,107.18v-.17c0-13.09,10.13-24.09,24.01-24.09,7.25,0,12.22,2.36,16.15,5.76.7.61,1.66,1.92,1.66,3.67,0,2.71-2.18,4.8-4.89,4.8-1.31,0-2.44-.52-3.14-1.05-2.71-2.27-5.67-3.93-9.86-3.93-7.68,0-13.36,6.63-13.36,14.67v.17c0,8.21,5.67,14.75,13.79,14.75,4.19,0,7.42-1.66,10.3-4.1.61-.52,1.66-1.13,2.88-1.13,2.53,0,4.54,2.1,4.54,4.63,0,1.4-.52,2.53-1.48,3.32-4.1,3.93-9.08,6.55-16.76,6.55-13.71,0-23.83-10.74-23.83-23.83Z"
			/>
			<path
				class="cls-2"
				d="m65.47,107.18v-.17c0-13.18,10.48-24.09,24.62-24.09s24.53,10.74,24.53,23.92v.17c0,13.09-10.48,24.01-24.7,24.01s-24.44-10.74-24.44-23.83Zm38.59,0v-.17c0-8.12-5.85-14.84-14.14-14.84s-13.88,6.63-13.88,14.67v.17c0,8.03,5.85,14.75,14.05,14.75s13.97-6.63,13.97-14.58Z"
			/>
			<path
				class="cls-2"
				d="m129.36,124.16c-4.48-4.34-7.16-10.38-7.16-17.03v-.17c0-13.18,10.48-24.09,24.62-24.09,9.05,0,16.56,4.4,20.8,11.01l-7.92,7.21c-2.06-5.25-6.88-8.97-13.05-8.97-8.47,0-13.88,6.63-13.88,14.67v.17c0,4.52,1.85,8.62,4.93,11.35l-8.33,5.86Z"
			/>
			<path
				class="cls-1"
				d="m146.77,148.98c-12.07,0-23.62-5.08-31.69-13.95-1.93-2.12-1.77-5.39.34-7.32,2.12-1.93,5.39-1.77,7.32.34,6.11,6.71,14.87,10.57,24.03,10.57,17.78,0,32.24-14.22,32.24-31.71,0-6.72-2.11-13.14-6.1-18.57-1.7-2.3-1.2-5.55,1.1-7.24,2.31-1.7,5.55-1.2,7.24,1.1,5.31,7.22,8.12,15.76,8.12,24.71,0,23.2-19.11,42.07-42.6,42.07Z"
			/>
			<path
				class="cls-1"
				d="m160.06,92.23c2.23-1.77,5.52-1.34,7.13,1,2.65,3.85,4.16,8.52,4.16,13.54v.17c0,13.09-10.48,24.01-24.7,24.01-7.06,0-13.19-2.71-17.55-7.06-2.1-2.1-1.89-5.56.43-7.4l.09-.07c2.11-1.67,5.11-1.31,6.89.7,2.5,2.81,6.09,4.58,10.3,4.58,8.56,0,13.97-6.63,13.97-14.58v-.17c0-2.95-.77-5.72-2.16-8.05-1.28-2.15-.8-4.9,1.16-6.46l.27-.21Z"
			/>
		</svg>
	);
};
