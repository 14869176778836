import { useLocation } from '@solidjs/router';
import { createEffect, onCleanup } from 'solid-js';
import ReloadPrompt from './ReloadPrompt';
import { Logo } from './icons/Logo';
import { buttonVariants } from './ui/button';
import { User } from './icons/User';
import { cn } from '~/lib/utils';
import { A } from './A';
import { createQuery } from '@tanstack/solid-query';
import { getUserQuery } from '~/lib/loaders';

export function Header() {
	const location = useLocation();
	const userQuery = createQuery(getUserQuery);

	const timeout = setTimeout(() => {
		document.getElementById('app')?.classList.remove('loading');
	}, 0);

	onCleanup(() => {
		clearTimeout(timeout);
	});

	createEffect(() => {
		if (location.pathname === '/') {
			document.getElementById('app')?.classList.add('home');
		} else {
			document.getElementById('app')?.classList.remove('home');
		}
	});

	return (
		<>
			<header class="flex items-end justify-between px-6">
				{userQuery.data?.UserAttributes != null ? (
					<A href="/" class="pt-4">
						<Logo class="size-44" />
						<span class="sr-only">Worldcoo logo</span>
					</A>
				) : (
					<span class="pt-4">
						<Logo class="size-44" />
						<span class="sr-only">Worldcoo logo</span>
					</span>
				)}

				<A
					href="/login"
					class={cn(
						buttonVariants({ variant: null, size: 'icon' }),
						'mx-2 my-10 rounded-full bg-background p-1.5 shadow transition-transform hover:scale-110',
					)}
				>
					<User class="size-[56px]" />
					<span class="sr-only">User</span>
				</A>
			</header>
			<ReloadPrompt />
		</>
	);
}
