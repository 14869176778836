import { createTRPCClient, httpBatchLink } from '@trpc/client';
import type { AppRouter } from '@donors/api';

export const client = createTRPCClient<AppRouter>({
	links: [
		httpBatchLink({
			url: import.meta.env.VITE_API_URL,
			async headers() {
				return {
					authorization: sessionStorage.getItem('accessToken') ?? undefined,
				};
			},
		}),
	],
});
