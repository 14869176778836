import { createUniqueId, type JSX } from 'solid-js';

export const Donations = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => {
	const id = createUniqueId();

	return (
		<svg width="75" height="74" viewBox="0 0 75 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path={`url(#clip0_708_161_${id})`}>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M57.2004 24.1654C55.1317 21.9166 51.5073 19.3431 47.0815 21.9565C36.5755 28.1601 46.3811 48.1663 52.859 49.9021C59.337 51.6379 77.832 39.2148 71.8353 28.5893C69.3092 24.1132 64.8836 24.5297 61.9677 25.4428C60.3201 25.9588 58.3693 25.4361 57.2004 24.1654ZM66.1884 28.414C65.6705 28.0404 64.9478 28.1573 64.5742 28.6752C64.2006 29.1931 64.3175 29.9158 64.8354 30.2894C65.3178 30.6374 65.7859 31.1335 66.1996 31.8429C66.7264 32.7464 66.9715 33.6476 67.0047 34.5362C67.0285 35.1743 67.5651 35.6723 68.2033 35.6485C68.8414 35.6247 69.3394 35.0881 69.3156 34.4499C69.268 33.1762 68.9128 31.9053 68.1973 30.6781C67.6328 29.71 66.9517 28.9647 66.1884 28.414Z"
					fill={`url(#paint0_linear_708_161_${id})`}
				/>
			</g>
			<g clip-path={`url(#clip1_708_161_${id})`}>
				<g filter={`url(#filter0_b_708_161_${id})`}>
					<g filter={`url(#filter1_b_708_161_${id})`}>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M30.372 17.2574C33.6819 13.6594 39.4811 9.54177 46.5623 13.7231C63.3719 23.649 47.683 55.6589 37.3183 58.4361C26.9535 61.2133 -2.63847 41.3364 6.95616 24.3356C10.998 17.1739 18.0789 17.8402 22.7444 19.3013C25.3806 20.1268 28.5018 19.2905 30.372 17.2574Z"
							fill="#C72752"
							fill-opacity="0.35"
						/>
						<path
							d="M46.7504 13.4045C43.1124 11.2564 39.7787 11.2327 36.9313 12.2235C34.1015 13.2083 31.775 15.1858 30.0997 17.0069C28.3265 18.9345 25.3544 19.7309 22.8549 18.9482C20.4936 18.2087 17.49 17.6593 14.547 18.2215C11.5857 18.787 8.7104 20.4744 6.63393 24.1537C4.17224 28.5156 4.23025 33.0626 5.84873 37.357C7.4632 41.6408 10.6303 45.6793 14.4049 49.0776C18.1815 52.4777 22.5869 55.2557 26.7073 57.0107C30.809 58.7577 34.6991 59.521 37.414 58.7935C40.1289 58.0661 43.1162 55.46 45.7949 51.8962C48.4858 48.3161 50.912 43.7076 52.4825 38.8747C54.0522 34.0444 54.7758 28.9634 54.0321 24.4463C53.2865 19.918 51.0632 15.9512 46.7504 13.4045Z"
							stroke={`url(#paint1_linear_708_161_${id})`}
							stroke-width="0.74"
							stroke-linecap="round"
						/>
					</g>
					<g filter={`url(#filter2_d_708_161_${id})`}>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M18.1559 27.0558C18.9845 26.458 19.1716 25.3017 18.5739 24.4731C17.9761 23.6445 16.8198 23.4573 15.9912 24.0551C14.7698 24.9363 13.6801 26.1287 12.7769 27.6777C11.6321 29.6412 11.0637 31.6748 10.9876 33.7127C10.9495 34.7337 11.7463 35.5923 12.7673 35.6304C13.7883 35.6685 14.6469 34.8717 14.6851 33.8507C14.7381 32.429 15.1304 30.987 15.9733 29.5415C16.6351 28.4064 17.384 27.6126 18.1559 27.0558Z"
							fill={`url(#paint2_linear_708_161_${id})`}
						/>
					</g>
				</g>
			</g>
			<defs>
				<filter
					id={`filter0_b_708_161_${id}`}
					x="-19.6709"
					y="-12.7775"
					width="98.3667"
					height="96.2151"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_708_161" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_708_161" result="shape" />
				</filter>
				<filter
					id={`filter1_b_708_161_${id}`}
					x="-19.6709"
					y="-12.7775"
					width="98.3667"
					height="96.2151"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
					<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_708_161" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_708_161" result="shape" />
				</filter>
				<filter
					id={`filter2_d_708_161_${id}`}
					x="7.7305"
					y="20.4495"
					width="20.9607"
					height="24.9497"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB"
				>
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="3.25583" dy="3.25583" />
					<feGaussianBlur stdDeviation="3.25583" />
					<feColorMatrix type="matrix" values="0 0 0 0 0.8 0 0 0 0 0.156863 0 0 0 0 0.32549 0 0 0 0.5 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_708_161" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_708_161" result="shape" />
				</filter>
				<linearGradient
					id={`paint0_linear_708_161_${id}`}
					x1="37.9677"
					y1="45.912"
					x2="73.1123"
					y2="24.1482"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#942050" />
					<stop offset="1" stop-color="#FF2E54" />
				</linearGradient>
				<linearGradient
					id={`paint1_linear_708_161_${id}`}
					x1="2.00049"
					y1="21.9332"
					x2="57.3696"
					y2="57.8682"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="white" stop-opacity="0.25" />
					<stop offset="1" stop-color="white" stop-opacity="0" />
				</linearGradient>
				<linearGradient
					id={`paint2_linear_708_161_${id}`}
					x1="16.9827"
					y1="36.9134"
					x2="27.5402"
					y2="20.2292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="white" />
					<stop offset="1" stop-color="white" stop-opacity="0.2" />
				</linearGradient>
				<clipPath id={`clip0_708_161_${id}`}>
					<rect width="37" height="37" fill="white" transform="translate(43.3687 13.842) rotate(15)" />
				</clipPath>
				<clipPath id={`clip1_708_161_${id}`}>
					<rect width="59.2" height="59.2" fill="white" transform="translate(-4.68005 16.0621) rotate(-15)" />
				</clipPath>
			</defs>
		</svg>
	);
};
