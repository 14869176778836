import { createUniqueId, type JSX } from 'solid-js';

export const AddDonation = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => {
	const id = createUniqueId();

	return (
		<svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.5 41.8333C33.0059 41.8333 42.3333 32.5059 42.3333 21C42.3333 9.49403 33.0059 0.166626 21.5 0.166626C9.99403 0.166626 0.666626 9.49403 0.666626 21C0.666626 32.5059 9.99403 41.8333 21.5 41.8333ZM23.0625 14.75C23.0625 13.887 22.3629 13.1875 21.5 13.1875C20.637 13.1875 19.9375 13.887 19.9375 14.75V19.4375H15.25C14.387 19.4375 13.6875 20.137 13.6875 21C13.6875 21.8629 14.387 22.5625 15.25 22.5625H19.9375V27.25C19.9375 28.1129 20.637 28.8125 21.5 28.8125C22.3629 28.8125 23.0625 28.1129 23.0625 27.25V22.5625H27.75C28.6129 22.5625 29.3125 21.8629 29.3125 21C29.3125 20.137 28.6129 19.4375 27.75 19.4375H23.0625V14.75Z"
				fill={`url(#paint0_linear_705_60_${id})`}
			/>
			<defs>
				<linearGradient
					id={`paint0_linear_705_60_${id}`}
					x1="0.666626"
					y1="41.8333"
					x2="43.2211"
					y2="1.09402"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#F18E44" />
					<stop offset="1" stop-color="#F9E7C8" />
				</linearGradient>
			</defs>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.5 41.8333C33.0059 41.8333 42.3333 32.5059 42.3333 21C42.3333 9.49403 33.0059 0.166626 21.5 0.166626C9.99403 0.166626 0.666626 9.49403 0.666626 21C0.666626 32.5059 9.99403 41.8333 21.5 41.8333ZM23.0625 14.75C23.0625 13.887 22.3629 13.1875 21.5 13.1875C20.637 13.1875 19.9375 13.887 19.9375 14.75V19.4375H15.25C14.387 19.4375 13.6875 20.137 13.6875 21C13.6875 21.8629 14.387 22.5625 15.25 22.5625H19.9375V27.25C19.9375 28.1129 20.637 28.8125 21.5 28.8125C22.3629 28.8125 23.0625 28.1129 23.0625 27.25V22.5625H27.75C28.6129 22.5625 29.3125 21.8629 29.3125 21C29.3125 20.137 28.6129 19.4375 27.75 19.4375H23.0625V14.75Z"
				fill={`url(#paint0_linear_705_60)_${id}`}
			/>
		</svg>
	);
};
