import type { Component } from 'solid-js';
import { Show } from 'solid-js';
import { useRegisterSW } from 'virtual:pwa-register/solid';
import { pwaInfo } from 'virtual:pwa-info';

console.log(pwaInfo);

const ReloadPrompt: Component = () => {
	// replaced dynamically
	const reloadSW = '__RELOAD_SW__';
	const {
		needRefresh: [needRefresh, setNeedRefresh],
		offlineReady: [offlineReady, setOfflineReady],
		updateServiceWorker,
	} = useRegisterSW({
		immediate: true,
		onRegisteredSW(swUrl, r) {
			console.log(`Service Worker at: ${swUrl}`);
			// @ts-expect-error just ignore
			if (reloadSW === 'true') {
				r &&
					setInterval(() => {
						console.log('Checking for sw update');
						r.update();
					}, 20_000 /* 20s for testing purposes */);
			} else {
				console.log(`SW Registered: ${r}`);
			}
		},
		onRegisterError(error) {
			console.error('SW registration error', error);
		},
	});

	const close = () => {
		setOfflineReady(false);
		setNeedRefresh(false);
	};

	return (
		<div>
			<Show when={offlineReady() || needRefresh()}>
				<div class="fixed bottom-0 right-0 z-10 m-4 rounded-md border bg-white p-3 text-left shadow-md">
					<div class="mb-2">
						<Show
							fallback={<span>New content available, click on reload button to update.</span>}
							when={offlineReady()}
						>
							<span>App ready to work offline</span>
						</Show>
					</div>
					<Show when={needRefresh()}>
						<button
							class="mr-5 rounded-md border border-gray-500 px-3 py-1 outline-none"
							onClick={() => updateServiceWorker(true)}
						>
							Reload
						</button>
					</Show>
					<button class="mr-5 rounded-md border border-gray-500 px-3 py-1 outline-none" onClick={() => close()}>
						Close
					</button>
				</div>
			</Show>
		</div>
	);
};

export default ReloadPrompt;
