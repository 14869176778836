import { render } from 'solid-js/web';
import { Router } from '@solidjs/router';
import { lazy, ParentProps } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';
import { MetaProvider } from '@solidjs/meta';
import { QueryClient, QueryClientProvider } from '@tanstack/solid-query';
import { SolidQueryDevtools } from '@tanstack/solid-query-devtools';
import Index from './routes/index';
import { Header } from './components/Header';
import './index.css';
import '@fontsource-variable/quicksand';
import { Transition } from 'solid-transition-group';
import { isNestedPage } from './lib/actions-mock';
import { Toaster } from './components/ui/sonner';
import { persistQueryClient, removeOldestQuery } from '@tanstack/solid-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const routes: RouteDefinition[] = [
	{
		path: '/',
		component: Index,
	},
	{
		path: '/donations',
		component: lazy(() => import('./routes/donations/(donations)')),
	},
	{
		path: '/donations/scan',
		component: lazy(() => import('./routes/donations/scan')),
	},
	{
		path: '/register',
		component: lazy(() => import('./routes/register')),
	},
	{
		path: '/confirm',
		component: lazy(() => import('./routes/confirm')),
	},
	{
		path: '/login',
		component: lazy(() => import('./routes/login')),
	},
	{
		path: '**',
		component: lazy(() => import('./routes/[...404]')),
	},
];

let reverseAnimation: boolean | undefined;

const queryClient = new QueryClient();

persistQueryClient({
	queryClient,
	persister: createSyncStoragePersister({
		storage: window.localStorage,
		retry: removeOldestQuery,
	}),
});

const App = (props: ParentProps) => (
	<QueryClientProvider client={queryClient}>
		<MetaProvider>
			<Header />
			<div class="relative h-full">
				<Transition
					onEnter={(el, done) => {
						el.animate(
							[{ transform: reverseAnimation ? 'translate(100%)' : 'translate(-100%)' }, { transform: 'translate(0)' }],
							{ duration: 300, easing: 'cubic-bezier(0.42, 0, 0.58, 1)' },
						)
							.finished.then(done)
							.catch(done);

						isNestedPage.value = false;
					}}
					onBeforeExit={(el) => {
						reverseAnimation = el.id === 'home' || isNestedPage.value;
					}}
					onExit={(el, done) => {
						el.animate(
							[
								{ position: 'absolute', top: 0, zIndex: -1, transform: `translate(0)` },
								{
									position: 'absolute',
									top: 0,
									zIndex: -1,
									transform: reverseAnimation ? 'translate(-100%)' : 'translate(100%)',
								},
							],
							{ duration: 300, easing: 'cubic-bezier(0.42, 0, 0.58, 1)' },
						)
							.finished.then(done)
							.catch(done);
					}}
				>
					{props.children}
				</Transition>
			</div>
		</MetaProvider>
		<Toaster position="top-center" richColors />
		<SolidQueryDevtools initialIsOpen={false} />
	</QueryClientProvider>
);

render(() => <Router root={App}>{routes}</Router>, document.getElementById('app')!);
