import { queryOptions, skipToken } from '@tanstack/solid-query';
import { getUserAttributes } from './authService';
import { client } from './trpc';
import { getDonations } from './actions-mock';

export const getUserQuery = () =>
	queryOptions({
		queryKey: ['users'],
		queryFn:
			sessionStorage.getItem('accessToken') == null
				? skipToken
				: // TODO: client.getUser.query is for testing purposes for now...
					// () => client.getUser.query('id_bilbo')
					() => getUserAttributes(),
		staleTime: Infinity,
	});

export type CertificateDataForm = {
	documentType: string;
	documentNumber: string;
	streetName: string;
	streetNumber: string;
	additionalInfo: string;
	postalCode: string;
	city: string;
	country: string;
	region: string;
	province: string;
};

type Donation = {
	id: string;
	image?: string;
	description?: {
		title: string;
		merchant: string;
		date: Date;
		transaction: {
			currency: 'EUR';
			amount: number;
		};
	};
	status: 'pending' | 'done';
};

export const getCertificateDataQuery = () =>
	queryOptions({
		queryKey: ['users', 'certificateData'],
		queryFn:
			sessionStorage.getItem('accessToken') == null
				? skipToken
				: () =>
						({
							documentType: '',
							documentNumber: '',
							streetName: '',
							streetNumber: '',
							additionalInfo: '',
							postalCode: '',
							city: '',
							country: '',
							region: '',
							province: '',
						}) satisfies CertificateDataForm,
		staleTime: Infinity,
	});

export const getDonationsQuery = () =>
	queryOptions({
		queryKey: ['donations'],
		// queryFn: (): Donation[] => [],
		queryFn: () => getDonations(0).list,
		staleTime: Infinity,
	});

export const getCountriesQuery = () =>
	queryOptions({
		queryKey: ['countries'],
		queryFn: () => client.getCountries.query(),
		placeholderData: [],
		staleTime: Infinity,
	});

export const getRegionsQuery = (country: string | undefined) =>
	queryOptions({
		queryKey: ['countries', country],
		queryFn: !country ? skipToken : () => client.getCountryRegions.query(country),
		staleTime: Infinity,
	});
